import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile, updatePhoneNumber } from "firebase/auth"; // Import fungsi signUpWithEmailAndPassword dari Firebase
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Loader from '../loader/Loader';
import api from '../../api/api';
import jwt from 'jwt-decode';
import Cookies from 'universal-cookie';
import { setCurrentUser } from '../../model/reducer/authReducer';
import FirebaseData from '../../utils/firebase/FirebaseData';
import { setlocalstorageOTP } from '../../utils/manageLocalStorage';

const Register = ({ closeModal }) => {
    const { auth } = FirebaseData();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [username, setUsername] = useState("");
    const [num, setNum] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update display name
            await updateDisplayName(user, username, num, password);

            console.log('Signed up user:', user.uid);
            const token = await user.getIdToken();

            api.register(username, email, num, password, user.uid)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        loginApiCall(email, user.uid);
                        // getCurrentUser()
                        // dispatch(setCurrentUser({ data: result.user }));
                        // toast.success("You're successfully Logged In");
                    }
                });

            // dispatch(setCurrentUser({ data: user }));

        } catch (error) {
            console.error('Sign up error:', error.message);
            setError(error.message);
        }
        setIsLoading(false);
    };

    const loginApiCall = async (email, Uid) => {
        await api.login(email, Uid)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    const decoded = jwt(result.data.access_token);

                    const tomorrow = new Date();
                    tomorrow.setDate(new Date().getDate() + 1);
                    cookies.set("jwt_token", result.data.access_token, {
                        expires: new Date(tomorrow)
                    });

                    getCurrentUser(result.data.access_token);
                    setlocalstorageOTP(Uid);
                    setError("");
                setEmail("");
                setPassword("");
                setUsername("");
                setIsLoading(false);
                closeModal();
                    // closeModalRef.current.click();
                }
                else {
                    setError(result.message);
                    // setOTP("");

                }

                // setisLoading(false);
            })
            .catch(error => console.log("error ", error));

    };

    const getCurrentUser = (token) => {
        api.getUser(token)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    dispatch(setCurrentUser({ data: result.user }));
                    // dispatch({ type: ActionTypes.SET_CURRENT_USER, payload: result.user });
                    toast.success("You're successfully Logged In");
                }
            });
    };

    const updateDisplayName = async (user, displayName) => {
        try {
            await updateProfile(user, { displayName: displayName });
        } catch (error) {
            console.error('Update display name error:', error.message);
            // Handle error updating display name
        }
    };


    return (
        <><div className="d-flex flex-row justify-content-between align-items-center header">
            <h5>Register</h5>
            <button type="button" className="" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                setError("");
                setEmail("");
                setPassword("");
                setUsername("");
                setIsLoading(false);
                closeModal()
            }}><AiOutlineCloseCircle /></button>
        </div>
            <div className="modal-body d-flex flex-column gap-3 align-items-center body">
                <form className='d-flex flex-column gap-3 form' onSubmit={handleRegister}>
                    {isLoading ? <Loader width='100%' height='auto' /> : null}
                    {error && <span className='error-msg'>{error}</span>}
                    <input type="text" placeholder="Display Name" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    <input type="text" placeholder="No. WA" value={num} onChange={(e) => setNum(e.target.value)} required />
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <button type='submit'>Register</button>
                </form>
            </div>
        </>
    );
};

export default Register;
