import React, { useState, useRef, useEffect } from 'react';
import './login.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import api from '../../api/api';
import { signInWithEmailAndPassword } from "firebase/auth";
import Cookies from 'universal-cookie';
import jwt from 'jwt-decode';
import { setlocalstorageOTP } from '../../utils/manageLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FirebaseData from '../../utils/firebase/FirebaseData';
import { setCurrentUser } from '../../model/reducer/authReducer';
import Register from '../Register/Register'; // Import komponen Register

const Login = (props) => {
    const { auth, messaging } = FirebaseData();

    const setting = useSelector(state => (state.setting));
    const [showRegisterModal, setShowRegisterModal] = useState(false); // State untuk mengontrol tampilan modal register

    const [fcm, setFcm] = useState('');
    useEffect(() => {
        try {
            setting.setting?.firebase && messaging && messaging?.getToken().then((res) => {
                console.log("resresres", res)
                setFcm(res);
            });

        } catch (error) {
            console.log(error);
        }
    }, [messaging, setting]);



    //initialize Cookies
    const cookies = new Cookies();
    const Navigate = useNavigate();
    const closeModalRef = useRef();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            // Handle signed in user
            console.log('Signed in user:', user.uid);
            debugger
            const token = await user.getIdToken();
            loginApiCall(user.email, user.uid);
            // getCurrentUser(token);
        } catch (error) {
            console.error('Sign in error:', error.message);
            setError(error.message);
        }
        setIsLoading(false);
    };

    const getCurrentUser = async (token) => {
        // Get user data using token
        // Example API call:
        api.getUser(token)
            .then(response => response.json())
            .then(result => {
                debugger
                if (result.status === 1) {
                    dispatch(setCurrentUser({ data: result.user }));
                    toast.success("You're successfully Logged In");
                }
            });
    };

    const loginApiCall = async (email, Uid) => {
        await api.login(email, Uid)
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    const decoded = jwt(result.data.access_token);

                    const tomorrow = new Date();
                    tomorrow.setDate(new Date().getDate() + 1);
                    cookies.set("jwt_token", result.data.access_token, {
                        expires: new Date(tomorrow)
                    });

                    getCurrentUser(result.data.access_token);
                    setlocalstorageOTP(Uid);
                    Navigate('/profile');
                    setError("");
                    setEmail("");
                    setPassword("");
                    setIsLoading(false);
                    closeModalRef.current.click();
                }
                else {
                    setError(result.message);
                    // setOTP("");

                }

                // setisLoading(false);
            })
            .catch(error => console.log("error ", error));

    };

    const handleTerms = () => {
        if (closeModalRef.current) {
            Navigate('/terms');
            closeModalRef.current.click();
        }
    };

    const handlePolicy = () => {
        if (closeModalRef.current) {
            Navigate('/policy/Privacy_Policy');
            closeModalRef.current.click();
        }
    };

    const { t } = useTranslation();

    return (
        <>
            <div className="modal fade login" id={props.modal_id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="loginLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ borderRadius: "10px" }}>
                        {!showRegisterModal &&
                            <>
                                <div className="d-flex flex-row justify-content-between align-items-center header">
                                    <h5>{t("Login")}</h5>
                                    <button type="button" className="" data-bs-dismiss="modal" aria-label="Close" ref={closeModalRef} onClick={() => {
                                        setError("");
                                        setEmail("");
                                        setPassword("");
                                        setIsLoading(false);
                                    }}><AiOutlineCloseCircle /></button>
                                </div>
                                <div className="modal-body d-flex flex-column gap-3 align-items-center body">
                                    {/* Your UI components */}
                                    <form className='d-flex flex-column gap-3 form' onSubmit={handleLogin}>
                                        {isLoading ? <Loader width='100%' height='auto' /> : null}
                                        {error && <span className='error-msg'>{error}</span>}
                                        <input type="email" placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        <input type="password" placeholder={t('password')} value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        <button type='submit'>{t("login_continue")}</button>
                                        {/* Tombol untuk membuka modal register */}
                                        <button type="button" onClick={() => setShowRegisterModal(true)}>Register</button>
                                    </form>
                                </div>
                            </>
                        }
                        {showRegisterModal && <Register closeModal={() => setShowRegisterModal(false)} />}

                    </div>
                </div>
            </div >
            {/* Modal register */}

        </>
    );
};

export default Login;
